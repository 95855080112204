<template>
    <div class="page">
        <div class="content">
            <div class="title">
                <div class="title_box">
                    <span>公司简介</span>
                </div>
            </div>
            <div class="text">
<!--                <div class="new_text_box" v-html="infoList.text">-->

<!--                </div>-->
                <div class="text_box">
                        <span>
                            江西六赞科技有限公司（简称“六赞科技”），是一家为客户的行业提供定制专业IT服务的人工智能应用软件开发互联网公司。
                        </span>
                </div>
                <div class="text_box">
                        <span>
                           六赞科技成立于2020年12月18日，六赞科技诞生在移动互联网时代、国家 “互联网+” 战略背景下。
                        </span>
                </div>
                <div class="text_box">
                        <span>
                            产业布局有：软件开发、人工智能应用软件开发、网络与信息安全软件开发、物联网技术研发、网络技术服务，技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广、信息技术咨询服务（除许可业务外，可自主依法经营法律法规非禁止或限制的项目）等领域。
                        </span>
                </div>
                <div class="text_box">
                        <span>
                            营销方面有：软件销售、人工智能硬件销售、光通信设备销售、计算器设备销售、网络设备销售、终端测试设备销售、移动终端设备销售。
                        </span>
                </div>
            </div>
            <div class="icon">
                <div class="icon_box">
                    <div class="icon_left">
                        <div>
                            <span>使命</span>
                        </div>
                        <div>
                            <span>SHARED MISSION</span>
                        </div>
                    </div>
                    <div class="icon_right">
                        <div class="icon_right_box">
                            <div>
                                <span>为用户创造价值</span>
                            </div>
                            <div>
                                <span>以技术研发为核心，为客户带来高质量、 <br/>高水平的解决方案</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {postApiConfig} from "@/api/apiConfig.js";
    export default {
        name: "box_1",
        data(){
            return{
                infoList:'',
            }
        },
        mounted() {
            this.getList()
        },
        methods:{
            getList(){
                postApiConfig("/pc/page/webInfo",{}).then((res) => {
                    // console.log("网站信息",res)
                    this.infoList = res.data.records[0]
                });
            },
        }

    }
</script>

<style scoped lang="scss">
    .page {
        width: 100%;
        background-size: 100% 100%;

        .content {
            width: 1200px;
            padding: 100px 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .title_box{
                    width: 40%;
                    background-image: url("../../assets/img/home/icon_1.png");
                    background-repeat: no-repeat;
                    background-position: center center;
                    text-align: center;
                    span{
                        color: #333333;
                        font-size: 30px;
                    }
                }
            }

            .text{
                width: 100%;
                margin-top: 30px;
                height: 200px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                .text_box:nth-child(1){
                    color: #333333;
                    font-size: 20px;
                }
                .text_box{
                    color: #666666;
                    font-size: 16px;
                }
                //
                .new_text_box{
                    p:nth-child(1){
                        color: #333333 !important;
                        font-size: 20px !important;
                    }
                    p{
                        color: #666666 !important;
                        font-size: 16px !important;
                    }
                }
            }

            .icon{
                width: 100%;
                margin-top: 30px;
                height: 300px;
                background-image: url("../../assets/img/home/icon_2.png");
                background-repeat: no-repeat;
                background-position: center bottom;
                .icon_box{
                    box-sizing: border-box;
                    padding: 50px 60px;
                    width: 100%;
                    /*height: 50%;*/
                    display: flex;
                    justify-content: space-between;
                    .icon_left{
                        flex: 1;
                        height: 100%;
                        div{
                            box-sizing: border-box;
                            &:nth-child(1){
                                color: #1882FF;
                                font-weight: bold;
                                font-size: 32px;
                                font-style: italic;
                            }
                            &:nth-child(2){
                                margin-top: 10px;
                                color: #1882FF;
                                font-size: 20px;
                                font-style:italic
                            }
                        }
                    }
                    .icon_right{
                        border-left: 1px solid #D3D3D3;
                        flex: 1;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .icon_right_box{
                            width: 80%;
                            div{
                                box-sizing: border-box;
                                text-align: left;
                                &:nth-child(1){
                                    color: #333333;
                                    font-weight: bold;
                                    font-size: 24px;
                                }
                                &:nth-child(2){
                                    margin-top: 15px;
                                    color: #333333;
                                    font-size: 18px;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
</style>
